import React, { Fragment } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Main from './routes/LandingPage'
import Page404 from './routes/Page404'

export default function App() {
  return (
    <Fragment>

      <Router>
        <Routes>
          <Route path="/" element={<Main />} >
            
          </Route>
          <Route element={<Page404/>} />
        </Routes>
      </Router>
    </Fragment>
  )
}
