import React, { Fragment } from 'react'
import { Layout,BackTop } from 'antd';

import { useWindowSize } from './Component/Util'
const { Content, Footer } = Layout;

require('../style/main.css')

export default function Main() {
    const size = useWindowSize()

    return (
        <Fragment>
            <Layout className='overwriteAntdStyle' style={{ fontFamily: 'Montserrat', height: 'auto', minHeight: '100%' }}>
                
                <Content style={{ backgroundColor: 'black' }} >
                    <h1 style={{textAlign:'center', marginTop: size.height/2-20, color:'white'}}>Byvidé</h1>
                </Content>
                <Footer style={{ backgroundColor: 'black' ,textAlign: 'center', color:'white'}}>
                    Copyright @2023 Byvidé.com
                    <BackTop />
                </Footer>
            </Layout>
        </Fragment>
    )
}